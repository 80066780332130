import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import LocalStorageProvider from "./Context/LocalStorageContext";

const widgetScriptId = document.querySelector('[id^="msp-critique-widget-js-"]')?.id;
if (widgetScriptId) {
	const widgetId = widgetScriptId.split("msp-critique-widget-js-")[1];
	const root = document.createElement("div");
	root.id = `msp-critique-widget-${widgetId}`;
	document.body.appendChild(root);

	ReactDOM.createRoot(document.getElementById(`msp-critique-widget-${widgetId}`)).render(
		<div className="bXNwLWNyaXRpcXVlLXdpZGdldC14eXo">
			<LocalStorageProvider>
				<App widgetId={widgetId} />
			</LocalStorageProvider>
		</div>,
	);
}
