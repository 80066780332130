import React, { Fragment, useEffect, useState } from "react";
import { Form, Input, Radio, Button, Space, Alert } from "antd";
import clsx from "clsx";
import * as Joi from "joi";
import style from "./style.module.css";
import { getQuestionsList } from "../../Utils";
import { useLocalStorage } from "../../Context/LocalStorageContext";

const Questions = ({ modalIsCentered, setReviewLink, clientId, customization, ...rest }) => {
	const [questions] = useState(getQuestionsList(rest));
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [answers, setAnswers] = useState({});
	const [showInput, setShowInput] = useState(false);
	const [atsFormErrors, setAtsFormErrors] = useState({});
	const [error, setError] = useState("");
	const [processing, setProcessing] = useState(false);
	const { savedQuestions, setSavedQuestions, setCritiqueResponseData, critiqueResponseData } = useLocalStorage();

	useEffect(() => {
		if (savedQuestions && questions) {
			const data = {
				firstName: critiqueResponseData.firstName,
				lastName: critiqueResponseData.lastName,
				email: critiqueResponseData.email,
			};

			const error = Object.keys(data).some((key) => {
				return ["none", "not_found_on_resume"].includes(data[key]?.toLowerCase());
			});

			if (error) {
				const values = {};
				Object.keys(data).forEach((key) => {
					values[key] = ["none", "not_found_on_resume"].includes(data[key]?.toLowerCase()) ? "" : data[key];
				});
				setAnswers(values);
			} else {
				setAnswers(savedQuestions);
			}
			const questionKeys = Object.keys(savedQuestions);

			let index;
			if (!error && "infoText" in questions[0]) {
				index = 1;
			} else {
				index = questions.findIndex(
					(res) => !questionKeys.includes(res.filedName) || !savedQuestions?.[res.filedName]
				);
			}

			if (index > -1) {
				setCurrentQuestion(index);
				return;
			}
			if (!("processed" in savedQuestions)) {
				setCurrentQuestion(questions.length - 1);
			}
		}
	}, [savedQuestions, questions, critiqueResponseData]);

	const protocol = window.location.protocol;

	const validationSchema = Joi.object({
		firstName: Joi.string().required().min(1),
		lastName: Joi.string().required().min(1),
		email: Joi.string()
			.email({ tlds: { allow: false } })
			.regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
			.required(),
		questions: Joi.object().required(),
	});

	const validationAtsFormSchema = Joi.object({
		firstName: Joi.string().required().min(1),
		lastName: Joi.string().required().min(1),
		email: Joi.string()
			.email({ tlds: { allow: false } })
			.regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)
			.required(),
	});

	const onFinish = async () => {
		try {
			const { error: JoiError } = validationSchema.validate(answers);
			if (JoiError) {
				return;
			}
			setProcessing(true);
			const critiqueId = rest.reviewLink.split("/");
			const response = await fetch(
				`${protocol}//${process.env.REACT_APP_API_DOMAIN}/v2/clients/${clientId}/critiques-public/${
					critiqueId[critiqueId.length - 1]
				}`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(answers),
				}
			);

			if (response.ok) {
				setReviewLink(rest.reviewLink);
				setSavedQuestions({ ...answers, processed: true });
			} else {
				// error
				setProcessing(false);
				const error = response.json();
				console.log("error: ", error);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const onNoInputChange = (event) => {
		setError("");

		setAtsFormErrors((prevState) => {
			delete prevState[event.target.name];
			return prevState;
		});

		setAnswers((prev) => ({
			...prev,
			[event.target.name]: event.target.value,
		}));
	};

	const onAnswerChange = (filedName, question) => (event) => {
		if (event.target.value === "No") {
			setShowInput(true);
			setAnswers((prev) => ({
				...prev,
				[event.target.name]: "",
			}));
		} else if (filedName === "questions") {
			setAnswers((prev) => ({
				...prev,
				questions: {
					...prev.questions,
					[question]: event.target.value,
				},
			}));
		} else {
			setShowInput(false);
			setAnswers((prev) => ({
				...prev,
				[event.target.name]: event.target.value,
			}));
		}
	};

	const handleNextQuestion = (question, value) => {
		if ("atsForm" === value) {
			const { error: joiError } = validationAtsFormSchema.validate(answers, { abortEarly: false });
			if (joiError) {
				const { details } = joiError;

				const formError = {};
				details.forEach((value) => {
					formError[value.context.key] = true;
				});
				setAtsFormErrors(formError);
				return;
			}
			setAtsFormErrors({});
			setCritiqueResponseData(answers, "insert");
		} else {
			if (question.type === "email") {
				const match = value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
				if (!match) {
					setError("Invalid Email Address");
					return;
				}
			}
			setShowInput(false);
		}
		handleSaveAnswers();
		setCurrentQuestion(currentQuestion + 1);
	};

	const handleSaveAnswers = () => {
		setSavedQuestions(answers);
	};

	return (
		<Form
			name="questions"
			onFinish={onFinish}
			className={clsx({
				[style.questions]: true,
				[style["question-centered-padding"]]: modalIsCentered,
			})}
		>
			{questions.map((question, idx) => {
				if (currentQuestion !== idx) return null;
				return question.infoText ? (
					<Fragment key={question.filedName}>
						<Alert
							style={{
								marginBlock: "1rem",
							}}
							type="warning"
							message={question.infoText}
						/>
						{question.questions.map((_question, _idx) => {
							return (
								<Form.Item
									validateStatus={atsFormErrors[_question.filedName] ? "error" : ""}
									hasFeedback
									className={style["form-item-style"]}
									key={`${_idx}-${idx}`}
								>
									<p
										className={style.question}
										dangerouslySetInnerHTML={{
											__html: _question.question,
										}}
										style={{ color: customization.widgetBottomTextColor }}
									/>
									<Input
										className={style["no-input"]}
										value={answers[_question.filedName]}
										type={_question?.type}
										name={_question.filedName}
										onChange={onNoInputChange}
									/>
									{error && <Alert type="error" message={error} />}
								</Form.Item>
							);
						})}
						<Form.Item className={style["questions-btn"]}>
							<Button
								className={style["action_button"]}
								style={{
									background: customization.widgetActionButtonBgColor,
									color: customization.widgetActionButtonTextColor,
									borderColor: customization.widgetActionButtonBorderColor,
								}}
								onClick={() => {
									handleNextQuestion(question, "atsForm");
								}}
							>
								Next
							</Button>
						</Form.Item>
					</Fragment>
				) : (
					<Form.Item key={question.filedName}>
						<p
							className={style.question}
							dangerouslySetInnerHTML={{
								__html: question.question,
							}}
							style={{ color: customization.widgetBottomTextColor }}
						/>
						<Radio.Group
							name={question.filedName}
							onChange={onAnswerChange(question.filedName, question.question)}
						>
							<Space direction="vertical">
								{question.answers.map((answer, idx) => (
									<Radio
										key={idx}
										className={style.options}
										value={question.values?.[idx] || answer}
										style={{ color: customization.widgetBottomTextColor }}
									>
										{answer}
									</Radio>
								))}
							</Space>
						</Radio.Group>
						{showInput && (
							<Input
								className={style["no-input"]}
								type={question?.type}
								name={question.filedName}
								onChange={onNoInputChange}
							/>
						)}
						{error && <Alert type="error" message={error} />}
						<div className={style["questions-btn"]}>
							{currentQuestion < questions.length - 1 ? (
								<Button
									className={style["action_button"]}
									style={{
										background: customization.widgetActionButtonBgColor,
										color: customization.widgetActionButtonTextColor,
										borderColor: customization.widgetActionButtonBorderColor,
									}}
									disabled={answers[question.filedName]?.length < 1}
									onClick={() => {
										handleNextQuestion(question, answers[question.filedName]);
									}}
								>
									Next
								</Button>
							) : (
								<Button
									disabled={!answers[question.filedName]}
									type="primary"
									htmlType="submit"
									className={style["action_button"]}
									style={{
										background: customization.widgetActionButtonBgColor,
										color: customization.widgetActionButtonTextColor,
										borderColor: customization.widgetActionButtonBorderColor,
									}}
								>
									{processing ? "Processing..." : "Submit"}
								</Button>
							)}
						</div>
					</Form.Item>
				);
			})}
		</Form>
	);
};

export default Questions;
