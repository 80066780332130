import { useEffect, useMemo, useState } from "react";
import { ConfigProvider } from "antd";

import ReviewPage from "./components/ReviewPage";
import UploadCritique from "./components/UploadCritique";
import RenderBtn from "./components/RenderBtn";
import OrderConfirmation from "./components/OrderConfirmation";
import { getQuestionsList } from "./Utils";
import { useLocalStorage } from "./Context/LocalStorageContext";
import CustomModal from "./components/CustomModal";
import { supportedCurrency } from "./Constants";
import CenteredWidget from "./components/CenteredWidget";

function App({ widgetId }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [currencyType, setCurrencyType] = useState(null);
	const [customizations, setCustomization] = useState({});
	const [clientId, setClientId] = useState(null);
	const [buttonPosition, setButtonPosition] = useState(null);

	const location = window.location;
	const url = new URL(window.location);
	const params = new URLSearchParams(url.search);
	const orderId = params.get("orderId");
	const userId = params.get("userId");

	const { savedQuestions, orderDetails, reviewLink, setReviewLink } = useLocalStorage();

	const showModal = ({ currentTarget }) => {
		setButtonPosition(currentTarget.getBoundingClientRect());
		setIsModalOpen((prevState) => !prevState);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		// fetch settings using widgetId, this should give us the "client_id"
		fetchWidgetSettings();
	}, []);

	const isQuestionPending = useMemo(() => {
		return [...getQuestionsList({}), { filedName: "processed" }].some(({ filedName }) => {
			return !savedQuestions?.[filedName];
		});
	}, [savedQuestions]);

	// un comment this after writing the code for fetching the settings
	// if (!Object.keys(customizations).length) {
	// 	return null;
	// }

	const fetchWidgetSettings = async () => {
		try {
			const response = await fetch(
				`${location.protocol}//${process.env.REACT_APP_API_DOMAIN}/v2/widget/${widgetId}`,
				{
					method: "GET",
				},
			);

			if (response.ok) {
				const { data } = await response.json();
				const { client_id, ...rest } = data;
				setCustomization(rest);
				setIsModalOpen(rest.modalIsOpen || false);
				setClientId(client_id.client_id);
				setCurrencyType(client_id.currencyType);
			} else {
				// error
				const error = await response.json();
				console.log("error: ", error);
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (orderId && clientId && userId) {
			setIsModalOpen(true);
		}
	}, [orderId, clientId, userId, orderDetails]);

	const height = !isQuestionPending && !Object.keys(orderDetails).length ? "80vh" : "";

	const renderComponent = ({ modalIsCentered }) => {
		if ((orderId && clientId && userId) || ("orderId" in orderDetails && "userId" in orderDetails)) {
			return (
				<OrderConfirmation
					orderId={orderId}
					clientId={clientId}
					userId={userId}
					{...orderDetails}
					customizations={customizations}
				/>
			);
		} else if (isQuestionPending) {
			if (modalIsCentered) {
				return (
					<CenteredWidget
						clientId={clientId}
						savedQuestions={savedQuestions}
						reviewLink={reviewLink}
						setReviewLink={setReviewLink}
						customizations={customizations}
					/>
				);
			} else {
				return (
					<UploadCritique
						// this should come from the settings above
						clientId={clientId}
						savedQuestions={savedQuestions}
						reviewLink={reviewLink}
						setReviewLink={setReviewLink}
						customizations={customizations}
					/>
				);
			}
		} else if (reviewLink) {
			return <ReviewPage reviewLink={reviewLink} />;
		} else {
			return null;
		}
	};

	return supportedCurrency.includes(currencyType) ? (
		<>
			<ConfigProvider
				theme={{
					token: {
						colorPrimary: customizations?.widgetButtonBgColor || "#344980",
					},
				}}
			>
				<RenderBtn customizations={customizations} showModal={showModal} />
				<CustomModal
					isOpen={isModalOpen}
					modalIsCentered={customizations?.modalIsCentered}
					widgetTopHeaderTextColor={customizations?.widgetTopHeaderTextColor}
					buttonPosition={buttonPosition}
					sideBtn={customizations?.type === "rectangle"}
					bodyStyle={{
						height,
						padding: 0,
					}}
					onCancel={handleCancel}
				>
					{renderComponent({ modalIsCentered: customizations?.modalIsCentered })}
				</CustomModal>
			</ConfigProvider>
		</>
	) : null;
}

export default App;

// how would you read the client id?
//  -> this can be fetched using the widget id
// do we need widget api key?
// -> no any one can use it, need to open this on the api
