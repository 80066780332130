import React from "react";
import clsx from "clsx";
import { CloseCircleOutlined, CloseOutlined } from "@ant-design/icons";

import styles from "./customModal.module.css";

const CustomModal = ({
	sideBtn,
	isOpen,
	children,
	bodyStyle,
	onCancel,
	modalIsCentered,
	widgetTopHeaderTextColor,
}) => {
	return (
		<div
			className={clsx(
				[
					!modalIsCentered &&
						(sideBtn ? styles["modal-position-for-side-btn"] : styles["modal-position-for-float-btn"]),
					modalIsCentered
						? clsx([styles["modal-container"], styles["centered-modal-container"]])
						: styles["modal-container"],
				],
				{
					[styles["center-modal"]]: modalIsCentered,
					[styles["modal-display-none"]]: !isOpen,
				}
			)}
		>
			<div style={bodyStyle} className={styles["modal-content"]}>
				<button className={styles["modal-close-icon"]} onClick={onCancel}>
					{modalIsCentered ? (
						<CloseCircleOutlined
							style={{ color: widgetTopHeaderTextColor || "#EAEFFB80" }}
						/>
					) : (
						<CloseOutlined className={styles["close-icon"]} />
					)}
				</button>
				<>{children}</>
			</div>
		</div>
	);
};

export default CustomModal;
