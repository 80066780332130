import style from "./style.module.css";

function UploadHeader({ customizations }) {
	const { widgetTopBgColor, widgetTopTextColor } = customizations;
	return (
		<div className={style.container} style={{ backgroundColor: widgetTopBgColor, color: widgetTopTextColor }}>
			<p className={style.title} style={{ color: widgetTopTextColor }}>
				Get A FREE AI <br /> Resume REVIEW
			</p>
			<p className={style.description} style={{ color: widgetTopTextColor }}>
				Receive instant feedback on how
				<br />
				your resume is perceived by
				<br />
				potential employers.
			</p>
		</div>
	);
}

export default UploadHeader;
