import { FileZipOutlined } from "@ant-design/icons";
import React from "react";

import style from "./style.module.css";
import { UploadButtonSvg } from "../SvgIcons";

function UploadButton({ customization, fileData, ...rest }) {
	return (
		<button className={style["parent-circle"]} style={{ backgroundColor: customization.iconBgColor }} {...rest}>
			<div className={style["dotted-circle"]} style={{ borderColor: customization.iconTextColor }}>
				{!fileData ? (
					<UploadButtonSvg styles={{ color: customization.iconTextColor || "#FFFFFF", fontSize: "40px" }} />
				) : (
					<FileZipOutlined style={{ color: customization.iconTextColor || "#FFFFFF", fontSize: "40px" }} />
				)}
			</div>
		</button>
	);
}

export default UploadButton;
