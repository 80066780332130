export const LOCAL_STORAGE_ACTION = {
	SAVE_QUESTIONS: {
		CASE: "SAVE_QUESTION",
		KEY: "questions",
	},
	DELETE_QUESTIONS: {
		CASE: "DELETE_QUESTIONS",
		KEY: "questions",
	},
	GET_QUESTION: {
		CASE: "GET_QUESTION",
		KEY: "questions",
	},
	GET_QUESTIONS: {
		CASE: "GET_QUESTIONS",
		KEY: "questions",
	},
	SAVE_REVIEW_LINK: {
		CASE: "SAVE_REVIEW_LINK",
		KEY: "review_link",
	},
	DELETE_REVIEW_LINK: {
		CASE: "DELETE_REVIEW_LINK",
		KEY: "review_link",
	},
	GET_REVIEW_LINK: {
		CASE: "GET_REVIEW_LINK",
		KEY: "review_link",
	},
	SAVE_CRITIQUE_REVIEW_RESPONSE: {
		CASE: "SAVE_CRITIQUE_REVIEW_RESPONSE",
		KEY: "critique_review",
	},

	DELETE_CRITIQUE_REVIEW_RESPONSE: {
		CASE: "DELETE_CRITIQUE_REVIEW_RESPONSE",
		KEY: "critique_review",
	},
	GET_CRITIQUE_REVIEW_RESPONSE: {
		CASE: "GET_CRITIQUE_REVIEW_RESPONSE",
		KEY: "critique_review",
	},

	SAVE_ORDER_DETAILS: {
		CASE: "SAVE_ORDER_DETAILS",
		KEY: "order_details",
	},

	DELETE_ORDER_DETAILS: {
		CASE: "DELETE_ORDER_DETAILS",
		KEY: "order_details",
	},
	GET_ORDER_DETAILS: {
		CASE: "GET_ORDER_DETAILS",
		KEY: "order_details",
	},

	DELETE_ALL_DETAILS: {
		CASE: "DELETE_ALL_DETAILS",
		KEY: "",
	},

	SAVE_PAUSE_TILL_DATE: {
		CASE: "SAVE_PAUSE_TILL_DATE",
		KEY: "pause_till_date",
	},

	GET_PAUSE_TILL_DATE: {
		CASE: "GET_PAUSE_TILL_DATE",
		KEY: "pause_till_date",
	},

	DELETE_PAUSE_TILL_DATE: {
		CASE: "DELETE_PAUSE_TILL_DATE",
		KEY: "pause_till_date",
	},
};
