import React, { useRef, useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useLocalStorage } from "../Context/LocalStorageContext";

function ReviewPage({ reviewLink }) {
	const iframeRef = useRef(null);
	const [showBackBtn, setShowBackBtn] = useState(false);
	const protocol = window.location.protocol;

	const { deleteAllDetails } = useLocalStorage();

	useEffect(() => {
		const handleMessage = (event) => {
			if (event.data.type === "message") {
				// Handle the message received from the iframe
				setShowBackBtn(event.data.payload);
			}

			if (event.data.type === "critiqueReviewError") {
				if (event.data.payload.error === "Critique not found!") {
					deleteAllDetails();
				}
			}

			if(event.data.type === "redirect") {
				if (event.data.payload.url) {
					window.location = event.data.payload.url;
				}
			}

		};

		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	const handleClick = () => {
		if (iframeRef.current) {
			setShowBackBtn(true);
			// Send a message to the iframe to navigate back
			iframeRef.current.contentWindow.postMessage({ type: "goBack" }, "*");
		}
	};
	return (
		<div style={{ height: "100%" }}>
			{showBackBtn && (
				<Button
					onClick={handleClick}
					style={{
						marginTop: "10px",
						marginLeft: "10px",
						marginBottom: "10px",
					}}
					type="default"
					icon={<ArrowLeftOutlined />}
					size={"small"}
				/>
			)}
			{/* {showBackBtn &&  <ArrowLeftOutlined onClick={handleClick} />} */}
			<iframe
				ref={iframeRef}
				title="critique"
				width="100%"
				height="100%"
				style={{
					borderRadius: "7px",
					border: 0,
				}}
				allow="fullscreen; accelerometer; clipboard-write; encrypted-media;"
				src={`${protocol}//${reviewLink}?fromWidget=true&redirectionUrl=${window.location.href.replaceAll(
					"&",
					"customEscape",
				)}`}
			/>
		</div>
	);
}

export default ReviewPage;
