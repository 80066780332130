import { useContext, createContext, useState, useEffect } from "react";
import moment from "moment";

import { deleteFromLocalStorage, getFromLocalStorage, saveToLocalStorageActions } from "../../Utils";
import { LOCAL_STORAGE_ACTION } from "../../Constants";

const LocalStorageContext = createContext({
	setSavedQuestions: (value) => {},
	savedQuestions: {},
	critiqueResponseData: {},
	setCritiqueResponseData: (value, action) => {},
	orderDetails: {},
	setOrderDetails: (value) => {},
	reviewLink: null,
	setReviewLink: (value) => {},
	deleteSavedOrderDetails: () => {},
	deleteReviewLink: () => {},
	deleteQuestions: () => {},
	deleteCritiqueResponseData: () => {},
	deleteAllDetails: () => {},
	pauseTillDate: null,
	setPauseTillDate: () => {},
});

export const useLocalStorage = () => useContext(LocalStorageContext);

const LocalStorageProvider = ({ children }) => {
	const [savedQuestions, setSavedQuestions] = useState({});
	const [critiqueResponseData, setCritiqueResponseData] = useState({});
	const [orderDetails, setOrderDetails] = useState({});
	const [reviewLink, setReviewLink] = useState(null);
	const [pauseTillDate, setPauseTillDate] = useState(null);

	useEffect(() => {
		getQuestionsFromLocalStorage();
		getCritiqueReviewResponseData();
		getSavedOrderDetails();
		getReviewlink();
		getPauseTillDate();
	}, []);

	const getQuestionsFromLocalStorage = () => {
		try {
			const data = getFromLocalStorage({ type: LOCAL_STORAGE_ACTION.GET_QUESTIONS.CASE });
			if (data) setSavedQuestions(data);
		} catch (e) {
			console.error(e);
		}
	};

	const getCritiqueReviewResponseData = () => {
		try {
			const data = getFromLocalStorage({ type: LOCAL_STORAGE_ACTION.GET_CRITIQUE_REVIEW_RESPONSE.CASE });
			if (data) setCritiqueResponseData(data);
		} catch (e) {
			console.error(e);
		}
	};

	const getSavedOrderDetails = () => {
		try {
			const data = getFromLocalStorage({ type: LOCAL_STORAGE_ACTION.GET_ORDER_DETAILS.CASE });
			if (data) setOrderDetails(data);
		} catch (e) {
			console.error(e);
		}
	};

	const getReviewlink = () => {
		try {
			const data = getFromLocalStorage({ type: LOCAL_STORAGE_ACTION.GET_REVIEW_LINK.CASE });
			if (data) setReviewLink(data);
		} catch (e) {
			console.error(e);
		}
	};

	const getPauseTillDate = () => {
		try {
			const data = getFromLocalStorage({ type: LOCAL_STORAGE_ACTION.GET_PAUSE_TILL_DATE.CASE });
			if (moment(data).isValid()) {
				if (moment().isSameOrBefore(moment(data))) {
					setPauseTillDate(data);
				} else {
					deleteAllDetails();
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleSavedQuestionChange = (value) => {
		setSavedQuestions(value);
		saveToLocalStorageActions({ type: LOCAL_STORAGE_ACTION.SAVE_QUESTIONS.CASE, payload: { data: value } });
	};

	const handleSaveCritiqueResponseData = (value, action) => {
		setCritiqueResponseData((prevState) => {
			const data = { ...prevState, ...value };
			saveToLocalStorageActions({
				type: LOCAL_STORAGE_ACTION.SAVE_CRITIQUE_REVIEW_RESPONSE.CASE,
				payload: { data },
			});
			return data;
		});
	};

	const handleSaveOrderDetails = (value) => {
		setOrderDetails(value);
		saveToLocalStorageActions({
			type: LOCAL_STORAGE_ACTION.SAVE_ORDER_DETAILS.CASE,
			payload: { data: value },
		});
	};

	const handleSaveReviewLink = (value) => {
		setReviewLink(value);
		saveToLocalStorageActions({
			type: LOCAL_STORAGE_ACTION.SAVE_REVIEW_LINK.CASE,
			payload: { data: value },
		});
	};

	const handleSavePauseTillDate = () => {
		const date = +moment().add(2, "weeks");
		setPauseTillDate(date);
		saveToLocalStorageActions({
			type: LOCAL_STORAGE_ACTION.SAVE_PAUSE_TILL_DATE.CASE,
			payload: {
				data: date,
			},
		});
	};

	const deleteReviewLink = (value) => {
		setReviewLink(null);
		deleteFromLocalStorage({
			type: LOCAL_STORAGE_ACTION.DELETE_REVIEW_LINK.CASE,
		});
	};

	const deleteSavedOrderDetails = () => {
		setOrderDetails({});
		deleteFromLocalStorage({
			type: LOCAL_STORAGE_ACTION.DELETE_ORDER_DETAILS.CASE,
		});
	};

	const deleteQuestions = (value) => {
		setSavedQuestions({});
		deleteFromLocalStorage({ type: LOCAL_STORAGE_ACTION.DELETE_QUESTIONS.CASE });
	};

	const deleteCritiqueResponseData = (value) => {
		setCritiqueResponseData({});
		deleteFromLocalStorage({
			type: LOCAL_STORAGE_ACTION.DELETE_CRITIQUE_REVIEW_RESPONSE.CASE,
		});
	};

	const deleteAllDetails = () => {
		setCritiqueResponseData({});
		setSavedQuestions({});
		setOrderDetails({});
		setReviewLink(null);
		setPauseTillDate(null);
		deleteFromLocalStorage({
			type: LOCAL_STORAGE_ACTION.DELETE_ALL_DETAILS.CASE,
		});
	};

	return (
		<LocalStorageContext.Provider
			value={{
				savedQuestions,
				setSavedQuestions: handleSavedQuestionChange,
				critiqueResponseData,
				setCritiqueResponseData: handleSaveCritiqueResponseData,
				setOrderDetails: handleSaveOrderDetails,
				orderDetails,
				reviewLink,
				setReviewLink: handleSaveReviewLink,
				deleteSavedOrderDetails,
				deleteReviewLink,
				deleteQuestions,
				deleteCritiqueResponseData,
				deleteAllDetails,
				pauseTillDate,
				setPauseTillDate: handleSavePauseTillDate,
			}}
		>
			{children}
		</LocalStorageContext.Provider>
	);
};

export default LocalStorageProvider;
