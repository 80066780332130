export const getQuestionsList = ({ firstName = "", lastName = "", email = "" }) => {
	const data = { firstName, lastName, email };
	const atsQuestions = [
		{
			question: `Is your first name <b>${data.firstName}</b>?`,
			answers: ["Yes", "No"],
			values: [data.firstName, "No"],
			filedName: "firstName",
			type: "text",
		},
		{
			question: `Is your last name <b>${data.lastName}</b>?`,
			answers: ["Yes", "No"],
			values: [data.lastName, "No"],
			filedName: "lastName",
			type: "text",
		},
		{
			question: `Is your email <b>${data.email}?</b>`,
			answers: ["Yes", "No"],
			values: [data.email, "No"],
			filedName: "email",
			type: "email",
		},
	];

	let atsErrorQuestions = [
		{
			question: `First name:`,
			filedName: "firstName",
			type: "text",
		},
		{
			question: `Last name:`,
			filedName: "lastName",
			type: "text",
		},
		{
			question: `Email:`,
			filedName: "email",
			type: "email",
		},
	];

	const error = Object.keys(data).some((key) => {
		return ["none", "not_found_on_resume"].includes(data[key]?.toLowerCase());
	});

	const atsError = [
		{
			infoText: "URGENT: ATS Scan Failure, Name and Email Was Not Detectable.",
			questions: atsErrorQuestions,
		},
	];
	return [
		...(error ? atsError : atsQuestions),
		{
			question: "Why did you request a resume review?",
			answers: [
				"I am looking for a job",
				"I am looking to relocate",
				"I am considering further education",
				"I am looking for a career change",
				"I am looking to keep my resume updated",
			],
			filedName: "questions",
		},
	];
};
