export default function UploadButtonSvg({ styles }) {
	const { color } = styles;
	return (
		<svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M32.5716 7.23868V41.0211H0.936523V0.645508H25.9785L32.5716 7.23868Z"
				stroke={color}
				strokeWidth="1.20976"
				strokeMiterlimit="10"
			/>
			<path d="M25.374 0.796631V7.3898H32.7535" stroke={color} strokeWidth="1.20976" strokeMiterlimit="10" />
			<path
				d="M13.5752 15.948L16.8718 12.6514L20.1684 15.948"
				stroke={color}
				strokeWidth="1.20976"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M16.8389 13.6792V25.6255"
				stroke={color}
				strokeWidth="1.20976"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M12.9375 29.0452H20.7404"
				stroke={color}
				strokeWidth="1.20976"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
		</svg>
	);
}
