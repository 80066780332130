import { LOCAL_STORAGE_ACTION } from "../Constants";

/**
 * @param {Object} action
 * @param action.type {"SAVE_QUESTION" | "SAVE_REVIEW_LINK" | "SAVE_CRITIQUE_REVIEW_RESPONSE" | "SAVE_ORDER_DETAILS" | "SAVE_PAUSE_TILL_DATE" | null}
 *
 *@param {Object} action.payload
 *@param {string} [action.payload.key]
 *@param {null | Object} action.payload.data
 */
export const saveToLocalStorageActions = (action = { type: null, payload: { data: null } }) => {
	try {
		const prefix = "widget__";
		switch (action.type) {
			case LOCAL_STORAGE_ACTION.SAVE_QUESTIONS.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.SAVE_QUESTIONS.KEY}`;
				const questionsData = localStorage.getItem(localStorageKey);
				let finalData = {};
				if (questionsData) {
					finalData = { ...JSON.parse(questionsData) };
				}
				finalData = {
					...(action.payload.key
						? {
								...finalData,
								[action.payload.key]: action.payload.data,
						  }
						: action.payload.data && action.payload.data),
				};
				localStorage.setItem(localStorageKey, JSON.stringify(finalData));
				return finalData;
			}

			case LOCAL_STORAGE_ACTION.SAVE_REVIEW_LINK.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.SAVE_REVIEW_LINK.KEY}`;
				localStorage.setItem(localStorageKey, action.payload.data);
				return;
			}

			case LOCAL_STORAGE_ACTION.SAVE_CRITIQUE_REVIEW_RESPONSE.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.SAVE_CRITIQUE_REVIEW_RESPONSE.KEY}`;
				localStorage.setItem(localStorageKey, JSON.stringify(action.payload.data));
				return;
			}
			case LOCAL_STORAGE_ACTION.SAVE_ORDER_DETAILS.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.SAVE_ORDER_DETAILS.KEY}`;
				localStorage.setItem(localStorageKey, JSON.stringify(action.payload.data));
				return;
			}
			case LOCAL_STORAGE_ACTION.SAVE_PAUSE_TILL_DATE.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.SAVE_PAUSE_TILL_DATE.KEY}`;
				localStorage.setItem(localStorageKey, action.payload.data);
				return;
			}
			default: {
				return;
			}
		}
	} catch (e) {
		console.log(e);
	}
};

/**
 * @param {Object} action
 * @param action.type {"GET_QUESTIONS" | "GET_QUESTION" | "GET_REVIEW_LINK" | "GET_CRITIQUE_REVIEW_RESPONSE" | "GET_ORDER_DETAILS" | "GET_PAUSE_TILL_DATE" | null}
 *@param {string} [action.key]
 */
export const getFromLocalStorage = (action = { type: null }) => {
	try {
		const prefix = "widget__";
		switch (action.type) {
			case LOCAL_STORAGE_ACTION.GET_QUESTIONS.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.GET_QUESTIONS.KEY}`;
				let questionsData = localStorage.getItem(localStorageKey);
				if (questionsData) {
					questionsData = { ...JSON.parse(questionsData) };
				}
				return questionsData;
			}
			case LOCAL_STORAGE_ACTION.GET_QUESTION.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.GET_QUESTION.KEY}`;
				let questionsData = localStorage.getItem(localStorageKey);
				if (questionsData) {
					questionsData = { ...JSON.parse(questionsData) };
				}
				return questionsData ? questionsData[action.key] : questionsData;
			}
			case LOCAL_STORAGE_ACTION.GET_REVIEW_LINK.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.GET_REVIEW_LINK.KEY}`;
				return localStorage.getItem(localStorageKey);
			}

			case LOCAL_STORAGE_ACTION.GET_CRITIQUE_REVIEW_RESPONSE.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.GET_CRITIQUE_REVIEW_RESPONSE.KEY}`;
				const data = localStorage.getItem(localStorageKey);
				return data ? JSON.parse(data) : data;
			}

			case LOCAL_STORAGE_ACTION.GET_ORDER_DETAILS.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.GET_ORDER_DETAILS.KEY}`;
				const data = localStorage.getItem(localStorageKey);
				return data ? JSON.parse(data) : data;
			}

			case LOCAL_STORAGE_ACTION.GET_PAUSE_TILL_DATE.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.GET_PAUSE_TILL_DATE.KEY}`;
				const data = localStorage.getItem(localStorageKey);
				return data ? JSON.parse(data) : data;
			}
			default: {
				return;
			}
		}
	} catch (e) {
		console.log(e);
	}
};

/**
 * @param {Object} action
 * @param action.type {"DELETE_QUESTIONS" | "DELETE_REVIEW_LINK" | "DELETE_CRITIQUE_REVIEW_RESPONSE" | "DELETE_ORDER_DETAILS" | "DELETE_ALL_DETAILS" | "DELETE_PAUSE_TILL_DATE" |null}
 *
 */
export const deleteFromLocalStorage = (action = { type: null }) => {
	try {
		const prefix = "widget__";
		switch (action.type) {
			case LOCAL_STORAGE_ACTION.DELETE_QUESTIONS.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.DELETE_QUESTIONS.KEY}`;
				localStorage.removeItem(localStorageKey);
				return;
			}
			case LOCAL_STORAGE_ACTION.DELETE_REVIEW_LINK.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.DELETE_REVIEW_LINK.KEY}`;
				localStorage.removeItem(localStorageKey);
				return;
			}

			case LOCAL_STORAGE_ACTION.DELETE_CRITIQUE_REVIEW_RESPONSE.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.DELETE_CRITIQUE_REVIEW_RESPONSE.KEY}`;
				localStorage.removeItem(localStorageKey);
				return;
			}

			case LOCAL_STORAGE_ACTION.DELETE_ORDER_DETAILS.CASE: {
				const localStorageKey = `${prefix}${LOCAL_STORAGE_ACTION.DELETE_ORDER_DETAILS.KEY}`;
				localStorage.removeItem(localStorageKey);
				return;
			}

			case LOCAL_STORAGE_ACTION.DELETE_ALL_DETAILS.CASE: {
				const allDetailsKeys = [
					LOCAL_STORAGE_ACTION.DELETE_QUESTIONS.KEY,
					LOCAL_STORAGE_ACTION.DELETE_REVIEW_LINK.KEY,
					LOCAL_STORAGE_ACTION.DELETE_CRITIQUE_REVIEW_RESPONSE.KEY,
					LOCAL_STORAGE_ACTION.DELETE_ORDER_DETAILS.KEY,
					LOCAL_STORAGE_ACTION.DELETE_ALL_DETAILS.KEY,
				].map((key) => `${prefix}${key}`);
				allDetailsKeys.forEach((key) => {
					localStorage.removeItem(key);
				});
				return;
			}
			default: {
				return;
			}
		}
	} catch (e) {
		console.log(e);
	}
};
